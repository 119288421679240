import React from "react"
import GoTop from "./GoTop"
import { initializeApp} from 'firebase/app';


// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
    apiKey: "AIzaSyClFnGEw0cIyo7Fo-5peAUfo7bneGamdAY",
    authDomain: "cloud-coach-2021.firebaseapp.com",
    databaseURL: "https://cloud-coach-2021-default-rtdb.firebaseio.com",
    projectId: "cloud-coach-2021",
    storageBucket: "cloud-coach-2021.appspot.com",
    messagingSenderId: "1001162620821",
    appId: "1:1001162620821:web:a1b37c3c128c3c3a9b409c",
    measurementId: "G-X0NPYE9W2L"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);


const Layout = ({ children }) => {
    return (
        <>
            {children}
            <GoTop scrollStepInPx="100" delayInMs="10.50" />
        </>
    )
}
export default Layout